import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as DetailsSlider } from "../../../assets/images/toggle.svg";
import { useTextField } from "../../../hooks/formHooks";
import {
  createContent,
  getContent,
  updateContent
} from "../../../redux/actions/content";
import { getTagsRequest } from "../../../redux/actions/tags";
import { apiUploadImage } from "../../../redux/api/fileUpload";
import { apiCreateTag, apiTags } from "../../../redux/api/tags";
import BluePopUp from "../../global/BluePopUp/BluePopUp";
import Button from "../../global/Button/Button";
import Checkbox from "../../global/Checkbox/Checkbox";
import EditorText from "../../global/EditorText/EditorText";
import Input from "../../global/form/Input/Input";
import MediaFormat from "../../global/MediaFormat/MediaFormat";
import OptionsDiversityAspects from "../CreateContent/OptionsDiversityAspects/OptionsDiversityAspects";
import SuggestionTags from "../SuggestionTags/SuggestionTags";
import "./CreateContent.scss";
import FrontCover from "./FrontCover/FrontCover";

const CreateContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [displayError, setDisplayError] = useState(false);
  const tags = useSelector((state) => state.tags?.data);
  const [periodDetails, setPeriodDetails] = useState(false);
  const content = useSelector((state) => state.content?.data);
  const [listTags, setListTags] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [listTagsID, setListTagsID] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [title, setTitle, validTitle] = useTextField("");
  const [mediaType, setMediaType] = useState("TEXT");
  const [isAccessible, setIsAccessible] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [contentText, setContentText] = useState({
    content: { text: "" },
  });
  const [contentOriginUrl, setContentOriginUrl, validContentOriginUrl] =
    useTextField("");
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [coverNotice, setCoverNotice] = useState(false);
  const [visitedSuggestion, setVisitedSuggestion] = useState(false);
  const [textDiversity, setTextDiversity] = useState("");
  const [listDiversity, setListDiversity] = useState([]);
  const [fixedDiversityItems, setFixedDiversityItems] = useState([]);
  const [diversity, setDiversity] = useState(false);
  const concatListDiversity = listDiversity.concat(fixedDiversityItems);

  useEffect(() => {
    dispatch(getTagsRequest());
    id && dispatch(getContent(id));
    setDiversity(content?.diversityAspects?.length >= 1 && true);
  }, [dispatch, id]);

  useEffect(() => {
    if (id && content) {
      setTitle(content.title);
      setMediaType(content.mediaType);
      setIsAccessible(content.isAccessible);
      setIsDraft(content.isDraft);
      setImageUrl(content.previewImage);
      setListDiversity(content.diversityAspects);
      setContentText(content?.content?.text);
      setContentOriginUrl(content?.content?.origin_url);
      let listTagsCopy = [];
      let listTagsIDCopy = [];
      content.tags?.forEach((tag) => {
        listTagsCopy.push(tag.name);
        listTagsIDCopy.push(tag.id);
      });
      setListTags(listTagsCopy);
      setListTagsID(listTagsIDCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const handlePeriodDetailsClick = () => {
    setPeriodDetails(!periodDetails);
  };

  const handleCreateContent = async (event) => {
    event && event.preventDefault();

    if (!validTitle) {
      setDisplayError(true);
      window.scrollTo(0, 0);
      return;
    }

    try {
      const payload = {
        title,
        platform: "ALEXANDRIA",
        mediaType,
        estimatedDuration: 1,
        isAccessible,
        shortDescription: "",
        content: {
          origin_url: contentOriginUrl,
          text: contentText?.text || "",
        },
        origin: "NATIONAL",
        tags: [],
        previewImage: "",
        diversityAspects: concatListDiversity,
        communicationVehicle: "",
        isDraft,
      };

      const existingTags = await apiTags();

      const normalizeText = (text) =>
        text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();

      const normalizeId = (text) =>
        text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().trim();

      const existingTagMap = new Map(
        existingTags.map((tag) => [normalizeText(tag.name), normalizeId(tag.id)])
      );

      let uniqueTagIds = new Set([...listTagsID.map(normalizeId)]);
      let tagsToCreate = [];

      listTags.forEach((tag) => {
        const normalizedTag = normalizeText(tag);

        if (existingTagMap.has(normalizedTag)) {
          const tagId = existingTagMap.get(normalizedTag);
          uniqueTagIds.add(tagId);
        } else {
          tagsToCreate.push(tag);
        }
      });

      if (tagsToCreate.length > 0) {
        const createdTags = await Promise.all(
          tagsToCreate.map((tag) => apiCreateTag({ name: tag }))
        );

        createdTags.forEach((tag) => {
          if (tag?.id) {
            uniqueTagIds.add(normalizeId(tag.id));
          }
        });
      }

      payload.tags = Array.from(uniqueTagIds);
      if (imageUrl && imageUrl !== content?.previewImage) {
        try {
          let uploadedImage = await apiUploadImage({ file: imageUrl });
          payload.previewImage = uploadedImage.fileUrl;
        } catch (error) {
          console.error("Erro ao carregar imagem", error);
        }
      } else {
        payload.previewImage = content?.previewImage || "";
      }

      if (id) {
        payload.content.text = contentText?.text || "";
        await dispatch(updateContent(id, payload));
      } else {
        await dispatch(createContent(payload));
      }

      sessionStorage.clear("suggestionTags");
      setVisitedSuggestion(false);
      setPopupMessage(id ? "Conteúdo editado com sucesso!" : "Conteúdo criado com sucesso!");
      setShowPopup(true);
    } catch (error) {
      console.error("Erro no processo:", error);
      setVisitedSuggestion(false);
      setPopupMessage("Erro, tente novamente");
      setShowPopup(true);
    }
  };

  return (
    <>
      <Helmet
        title={id ? `Edição de conteúdo - ${content?.title} - Alexandria` : 'Criar conteúdo - Alexandria'}
      />
      <form
        className="CreateContent"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="container-create">
          <div className="left-create">
            <h2>Criar conteúdo</h2>
            <div className="upper-fields-container">
              <div className="left-container">
                <Input
                  label="Link para o conteúdo"
                  style={{ width: 420, marginBottom: 30 }}
                  value={contentOriginUrl}
                  placeholder="http://www.replit.com"
                  valid={validContentOriginUrl}
                  displayError={displayError}
                  updateState={(value) => setContentOriginUrl(value)}
                  required
                  errorMessage="Título inválido. Mínimo 2 caracteres."
                  type="text"
                />
                <p className="title-box">Formato de mídia</p>
                <div className="header-sendmedia">
                  <div className="checkboxContent">
                    <MediaFormat
                      checked={mediaType === "TEXT"}
                      updateState={() => setMediaType("TEXT")}
                      label="Texto"
                    />
                    <MediaFormat
                      checked={mediaType === "AUDIO"}
                      updateState={() => setMediaType("AUDIO")}
                      label="Áudio"
                    />
                    <MediaFormat
                      checked={mediaType === "VIDEO"}
                      updateState={() => setMediaType("VIDEO")}
                      label="Vídeo"
                    />
                  </div>
                  <div
                    className={`togglePlan ${periodDetails ? "active" : undefined
                      }`}
                  >
                    <p style={{ color: periodDetails ? "black" : "#0505BE" }}>
                      Gratuito
                    </p>
                    <DetailsSlider onClick={() => handlePeriodDetailsClick()} />
                    <p style={{ color: periodDetails ? "#0505BE" : "black" }}>
                      Pago
                    </p>
                  </div>
                </div>
              </div>
              <Input
                label="Título"
                style={{ width: 420, marginBottom: 30 }}
                value={title}
                placeholder="Dê um título para seu conteúdo"
                valid={validTitle}
                displayError={displayError}
                updateState={(value) => setTitle(value)}
                required
                errorMessage="Título inválido. Mínimo 2 caracteres."
                type="text"
              />
            </div>
            <div>
              <label>Texto do Conteúdo</label>
              <EditorText
                textComplete={id ? content?.content.text : ""}
                setContent={setContentText}
              />
            </div>

            <div className="container-checkbox">
              <div className="checkboxLabel">
                <p className="title-box">Tem aspectos de diversidade?</p>
                <div className="checkboxContent">
                  <MediaFormat
                    checked={diversity}
                    updateState={() => setDiversity(true)}
                    label="Sim"
                  />
                  <MediaFormat
                    checked={!diversity}
                    updateState={() => setDiversity(false)}
                    label="Não"
                  />
                </div>
                <p className="checkbox-info">
                  Este conteúdo trabalha minorias de forma igualitária?
                </p>
              </div>
              <div className="checkboxLabel">
                <p className="title-box">Tem aspectos de acessibilidade??</p>
                <div className="checkboxContent">
                  <MediaFormat
                    checked={isAccessible}
                    updateState={() => setIsAccessible(true)}
                    label="Sim"
                  />
                  <MediaFormat
                    checked={!isAccessible}
                    updateState={() => setIsAccessible(false)}
                    label="Não"
                  />
                </div>
                <p className="checkbox-info">
                  Este conteúdo é acessível para indivíduos com deficiência
                  visual?
                </p>
              </div>
            </div>
            <OptionsDiversityAspects
              diversity={diversity}
              textDiversity={textDiversity}
              setTextDiversity={setTextDiversity}
              listDiversity={listDiversity}
              setListDiversity={setListDiversity}
              fixedDiversityItems={fixedDiversityItems}
              setFixedDiversityItems={setFixedDiversityItems}
            />
            <SuggestionTags
              storageSuggestion={handleCreateContent}
              listTagsID={listTagsID}
              setListTagsID={setListTagsID}
              listTags={listTags}
              setListTags={setListTags}
              visitedSuggestion={visitedSuggestion}
              setVisitedSuggestion={setVisitedSuggestion}
              text={contentText?.text}
            />
            <div className="accept-content">
              <Checkbox
                label="Visível apenas para mim"
                value={isDraft}
                checked={isDraft}
                updateState={() => setIsDraft(!isDraft)}
              />
              <Button
                type="submit"
                onClick={handleCreateContent}
                primaryColor="#CEEC58"
                className="save-button"
              >
                Indexar
              </Button>
            </div>
          </div>
          <div className="right-create">
            <FrontCover imageUrl={imageUrl} setImageUrl={setImageUrl} />
          </div>
        </div>
        {showPopup && (
          <BluePopUp
            text={popupMessage}
            onClick={() => {
              setShowPopup(false);
              popupMessage.includes("sucesso") &&
                history.push("/perfil/meus-conteudos");
            }}
          />
        )}
        {coverNotice && (
          <BluePopUp
            text="Adicione uma imagem ao seu conteúdo"
            onClick={() => {
              setCoverNotice(false);
              popupMessage.includes("sucesso");
            }}
          />
        )}
      </form>
    </>
  );
};

export default CreateContent;
