import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { removeSpecialCharacters } from "../../../helper/removeSpecialCharacters";
import useTags from "../../../hooks/useTags";
import { suggestionTags } from "../../../redux/actions/tags";
import Button from "../../global/Button/Button";
import SearchTagComment from "../../global/CommentText/SearchTagComment/SearchTagComment";
import DeleteTag from "../../global/DeleteTag/DeleteTag";
import Input from "../../global/form/Input/Input";
import "./SuggestionTags.scss";

const SuggestionTags = ({
  listTagsID,
  setListTagsID,
  listTags,
  setListTags,
  setVisitedSuggestion,
  text,
}) => {
  const dispatch = useDispatch();
  const [newTags, setNewTags] = useState([]);
  const [selectSuggestion, setSelectSuggestion] = useState([]);
  const [valueInput, setValueInput] = useState("");
  const tags = useTags();
  const [nameTag, setNameTag] = useState("");
  const [activeTags, setActiveTag] = useState(false);

  useEffect(() => {
    const payload = { text };
    if (activeTags) {
      dispatch(suggestionTags(payload))
        .then((json) => {
          const uniqueTags = new Set([...listTags]);
          const uniqueTagsID = new Set([...listTagsID]);

          json?.foundTags?.forEach((item) => {
            uniqueTags.add(item?.tag?.name);
            uniqueTagsID.add(item?.tag?.id?.toUpperCase());
          });

          setListTags(Array.from(uniqueTags));
          setListTagsID(Array.from(uniqueTagsID));

          setNewTags(json?.tagsSuggestion);
          setVisitedSuggestion(true);
        })
        .catch(() => { });
    }
  }, [activeTags]);

  const handleCreateTag = () => {
    if (valueInput.includes(",")) {
      const cleanedTag = removeSpecialCharacters(valueInput.trim());

      if (cleanedTag.length >= 2 && cleanedTag.length <= 40) {
        setListTags((prevTags) => {
          if (!prevTags.includes(cleanedTag)) {
            return [...prevTags, cleanedTag];
          }
          return prevTags;
        });

        setListTagsID((prevTagsID) => {
          if (!prevTagsID.includes(cleanedTag.toUpperCase())) {
            return [...prevTagsID, cleanedTag.toUpperCase()];
          }
          return prevTagsID;
        });
      }
      setValueInput("");
    }
  };

  const handleTagSelection = (item) => {
    const normalizedTag = item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();

    setListTags((prevTags) => {
      if (!prevTags.some(tag => tag.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase() === normalizedTag)) {
        return [...prevTags, item.name];
      }
      return prevTags;
    });

    setListTagsID((prevTagsID) => {
      if (!prevTagsID.includes(normalizedTag)) {
        return [...prevTagsID, item.id];
      }
      return prevTagsID;
    });

    handleClick(item.name);
    setValueInput("");
  };


  const handleClick = (name) => {
    setValueInput(name);
  };


  const resultInput = !valueInput
    ? tags
    : Array.isArray(tags) &&
    tags
      ?.filter((item) =>
        item.name.toLowerCase().includes(valueInput.toLocaleLowerCase())
      )
      ?.filter((item) => item.name !== valueInput);

  const cards = Array.isArray(resultInput) && resultInput?.slice(0, 4);

  const removeTag = (name) => {
    setListTags((prevTags) => prevTags.filter(tag => tag !== name));
    setListTagsID((prevTagsID) => prevTagsID.filter(tagID => tagID !== name.toUpperCase()));
  };

  const removeTagID = (name) => {
    listTagsID.splice(listTagsID.indexOf(name), 1);
  };

  return (
    <div className={`SuggestionTags`}>
      {activeTags || listTags?.length >= 1 ? (
        <div className="suggestion-container">
          <div className="title-tags">
            <h5>Tags</h5> <p>identificamos estas tags em seu conteúdo</p>
          </div>
          <div className="container-delete-tags">
            {listTags.map((item, key) => {
              return (
                <DeleteTag
                  item={item}
                  key={key}
                  onClick={() => {
                    removeTag(item);
                    removeTagID(item);
                  }}
                />
              );
            })}
          </div>
          <Input
            className={""}
            value={valueInput}
            updateState={setValueInput}
            type="text"
            placeholder="Adicionar tags"
            o
            nChange={handleCreateTag()}
          />
          {valueInput && cards.length > 0 ? (
            <div
              style={{ display: !valueInput ? "none" : "flex" }}
              className="container-search-tags"
            >
              {cards.map((item, key) => (
                <SearchTagComment
                  item={item}
                  key={key}
                  onClick={() => handleTagSelection(item)}
                />
              ))}
            </div>
          ) : (
            <p className="insert-tag">Insira uma vírgula depois de cada tag</p>
          )}
        </div>
      ) : (
        <div className="suggestion-container">
          <div className="title-tags">
            <h5>Tags</h5>
          </div>
          <Button
            onClick={() => text?.length >= 50 && setActiveTag(true)}
            style={{
              opacity: text?.length >= 50 ? "1" : "0.4",
              backgroundColor: "#CEEC58",
              cursor: text?.length >= 50 ? "pointer" : "not-allowed",
            }}
            children="Clique para detectar tags em seu conteúdo"
          />
        </div>
      )}
    </div>
  );
};

export default SuggestionTags;
